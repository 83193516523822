import React from "react";
import "./Press.scss";
import { Container, Header } from "semantic-ui-react";

export default function Press() {

  return (
    <Container text id="lectures">
      <Header as="h1">Presse</Header>
    </Container>
  );
}
